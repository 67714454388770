
import React, { useState } from 'react';
import style from './style.module.css';
import conditional from '../../utils/conditional';
import api from '../../api';
import { Link } from 'react-router-dom';
import phone from '../../utils/phone';
const SignUp = props => {
console.log(props)
    const [data, setData] = useState({ phone: '+1 ', firstName: '', lastName: '' });
    const [status, setStatus] = useState('waiting');

    const [showInvalidPhoneError, setShowInvalidPhoneError] = useState(false);

    const updateData = (field, e) => {
        const value = e.target.value;
        setData(data => ({ ...data, [field]: value }));
    };

    const containerClass = conditional('container', style, {
        pending: status === 'pending',
        finished: status === 'finished'
    });

    const submit = async () => {
        //create post to api to sign up user
        try {
            if (phone.format(data.phone).includes('2775378222')) {
                const result = await api.auth.sendVerificationCode(phone.format(data.phone));
                localStorage.setItem('phone', phone.format(data.phone));
                props.history.push('/verify');
                return;
            }

            localStorage.setItem('phone', phone.format(data.phone));
            let formatPhone = { ...data, phone: phone.format(data.phone) }
            formatPhone = { ...formatPhone, firstName: formatPhone.firstName, lastName: formatPhone.lastName }
            console.log(formatPhone)
            let result = await api.auth.signUp(formatPhone);
            console.log(props)
            props.onAuth(result);
            setStatus('finished');
            props.history.push('/verify');
        } catch (e) {
            if (e.response.data.code === 'phone-invalid') {
                setShowInvalidPhoneError(true);
            }
            console.log(e)
            // props.error(e);
        }
    };

    return (
        <div className={style.SignIn}>
            <div className={containerClass}>
                <div className={style.title + ' h1'}>Sign Up</div>
                <div className={style.description + ' h2'}>If you already have an account, you can sign in <Link className="h2" to="/sign-in">here</Link>.</div>
                <input className={style.textField + ' i1'} placeholder="Phone" inputMode="numeric" value={data.phone} onChange={e => updateData('phone', e)} />
                <input className={style.textField + ' i1'} placeholder="First Name" onChange={e => updateData('firstName', e)} />
                <input className={style.textField + ' i1'} placeholder="Last Name" onChange={e => updateData('lastName', e)} />
                {showInvalidPhoneError ? <div className={style.description + ' h2'}>This phone number is already used. Please sign in <Link className="h2" to="/sign-in">here</Link>.</div> : null}
                <div className={style.submit + ' b1'} onClick={submit}><span className={'h1'}>Sign Up</span></div>
            </div>
        </div>
    );

};

export default SignUp;
